const getDefaultUserData = () => ( {
    loggedIn: false,
    userId: null,
    user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        subscriptionTier: '',
    },
    familyMembers: [],
    userFavorites: [],
    sharedFamilyMembers: [],
    gifts: [],
    userInfo: {
        id: '',
        user_id: '',
        profilepicturepath: '',
        birthday: '',
        phoneNum: '',
        facebook: '',
        instagram: '',
        twitter: '',
        snapchat: '',
        tiktok: '',
        biography: '',
        selectedInterests: '',
        hobbiesAdditionalInfo: '',
        newHobbie: '',
        entertainmentAdditionalInfo: '',
        newEntertainment: '',
        foodDrinkAdditionalInfo: '',
        newFoodDrink: '',
        beautyWellnessAdditionalInfo: '',
        newBeautyWellness: '',
        petsAdditionalInfo: '',
        newPets: '',
        travelingAdditionalInfo: '',
        newTraveling: '',
        valuesTraitsAdditionalInfo: '',
        newValuesTraits: '',
        sportsAdditionalInfo: '',
        teamOrPlayer: ''
    },
}
);

export default getDefaultUserData;