import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/userContext';
import HttpsRedirect from 'react-https-redirect';
const App = React.lazy(() => import('./App'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const CreateMember = React.lazy(() => import('./pages/CreateMember'));
const CustomGiftingBoard = React.lazy(() => import('./pages/CustomGiftingBoard'));
const DeleteAccount = React.lazy(() => import('./pages/DeleteAccount'));
const GiftBoards = React.lazy(() => import('./pages/GiftBoards'));
const IdeasAndInspiration = React.lazy(() => import('./pages/IdeasAndInspiration'));
const Login = React.lazy(() => import('./pages/Login'));
const MyProfile = React.lazy(() => import('./pages/MyProfile'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const SubmitYourStory = React.lazy(() => import('./pages/SubmitYourStory'));
const TermsOfUse = React.lazy(() => import('./pages/TermsOfUse'));
const MyBoards = React.lazy(() => import('./pages/MyBoards'));
const FamilyMember = React.lazy(() => import('./pages/FamilyMember'));
const ThankYouForRegistering = React.lazy(() => import('./pages/ThankYouForRegistering'));
const LostPassword = React.lazy(() => import('./pages/LostPassword'));
const Loading = React.lazy(()=>import("./components/Loading"));
const Stories = React.lazy(() => import('./pages/Stories'));
const Admin = React.lazy(() => import('./pages/admin'));
const Board = React.lazy(() => import('./pages/Board'));
const Page404 = React.lazy(() => import('./pages/Page404'));
const Settings = React.lazy(() => import('./pages/Settings'));
const UpdateInfo = React.lazy(() => import('./pages/UpdateInfo'));
const Recover = React.lazy(() => import('./pages/Recover'));
const CreatePet = React.lazy(() => import('./pages/CreatePet'));
const ManageSuscription = React.lazy(() => import('./pages/ManageSuscription'));
const CreateMyProfile = React.lazy(() => import('./pages/CreateMyProfile'));
const NewSuscription = React.lazy(() => import('./pages/newSuscription'));
const CanceledSuscription = React.lazy(() => import('./pages/canceledSuscription'));
const ProtectedRoute = React.lazy(() => import('./components/protectedRoute'));
const Layout = React.lazy(() => import('./components/layout'));
const router = createBrowserRouter([
  {
    
    path: "/",
    element:
    <Layout> 
      <App/>
    </Layout>,
  },
  {
     path: "/AboutUs",
    element: <AboutUs/>,
  },
  {
     path: "/ContactUs",
     element:
            <Layout> 
              <ContactUs/>
            </Layout>,
  },
  {
     path: "/CreateMember",
    element: <CreateMember/>,
  },

  {
    path: "/Settings/DeleteAccount",
    element: <ProtectedRoute><DeleteAccount/></ProtectedRoute>,
  },
  {
    path: "/Settings/UpdateInfo",
    element: <ProtectedRoute><UpdateInfo/></ProtectedRoute>,
  },
  {
    path: "/Settings",
    element: <ProtectedRoute><Settings/></ProtectedRoute>,
  },
  {
    path: "/IdeasAndInspiration",
    element: <IdeasAndInspiration/>,
  },         
  {
    path: "/MyProfile",
    element: <ProtectedRoute>
              <Layout> 
                <MyProfile/>
              </Layout>
             </ProtectedRoute>,
  },
  {
    path: "/PrivacyPolicy",
    element: <PrivacyPolicy/>,
  },
  {
    path: "/LostPassword",
    element: <LostPassword/>,
  },
  {
    path: "/SignUp",
    element: <SignUp/>,
  },
  {
    path: "/SubmitYourStory",
    element: <SubmitYourStory/>,
  },
  {
    path: "/Login",
    element: <Login/>,
  },
  {
    path: "/TermsOfUse",
    element: <TermsOfUse/>,
  },
  {
    path: "/ThankYouForRegistering",
    element:
      <Layout> 
        <ThankYouForRegistering/>
      </Layout>,
  },
  
  {
    path: "/FamilyMember/:id/:preview?",
    element: 
      <Layout>
        <FamilyMember/>
      </Layout>,
  },
  {
    path: "/Stories/:id",
    element: <Stories/>,

  },
  {
    path: "/Admin",
    element: <Admin/>,

  },
  {
    path: '*',
    element: <Page404 />, 
  },
  {
    path: "/recover/:token/:email",
    element: <Recover/>,
  },
  {
    path: "/Settings/ManageSuscription",
    element: <ProtectedRoute>
                <Layout>
                  <ManageSuscription/>
                </Layout>   
              </ProtectedRoute>,
  },
  {
    path: "/MyProfile/CreateMyProfile",
    element: <CreateMyProfile/>
  },
  {
    path: "/CreatePet",
    element: <ProtectedRoute><CreatePet/></ProtectedRoute>,
  },
  {
    path: "/NewSubscription",
    element: <NewSuscription/>
  },
  {
    path: "/CanceledSubscription",
    element: <CanceledSuscription/>
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HttpsRedirect>
      <UserProvider>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
      </UserProvider>
  </HttpsRedirect>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
